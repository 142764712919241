import React, { useEffect } from "react";
import "./App.css";
import { HomePage } from "./pages/home-page/HomePage";
import { Routes, Route, useNavigate } from "react-router-dom";
import { AboutPage } from "./pages/about-page/AboutPage";
import { Grid, ThemeProvider } from "@mui/material";
import { INavbarMenuItemConfig, Navbar } from "./libs/ui-components/navbar/Navbar";
import { Footer } from "./libs/ui-components/footer/Footer";
import { routes } from "./routes";
import { theme } from "./theme";
import './services/i18n';
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import { useLanguage } from "./services/store/features/language/languageSelector";
import { scrollToTop } from "./services/tools";
import { setShowContactUsForm } from "./services/store/features/contact-us/contactUsSlice";
import { setLanguage } from "./services/store/features/language/languageSlice";
import { ContactUsForm } from "./libs/ui-components/contact-us-form/ContactUsForm";
import { useShowContactUsForm } from "./services/store/features/contact-us/contactUsSelector";

function App() {
    const { t } = useTranslation();
    const language = useLanguage();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const showContactUsForm = useShowContactUsForm();

    const navbarMenuItemsConfig = [
        {
            url: "/",
            text: t('navbar.home'), onClick: () => {
                navigate('/');
                scrollToTop()
            }
        },
        { url: "/about", text: t('navbar.about') },
        { url: "https://blog.augmented.energy", text: t('navbar.blog') },
        {
            url: "/contact", text: t('navbar.contact'), onClick: () => {
                dispatch(setShowContactUsForm(true));
            }
        },
        { url: "https://app.noos.energy", text: t('navbar.login') },
        {
            url: "/fr", text: t('navbar.language.fr'), onClick: () => {
                console.log('Setting language to : fr');
                dispatch(setLanguage('fr'));
            }, show: () => language !== "fr",
        },
        {
            url: "/en", text: t('navbar.language.en'), onClick: () => {
                console.log('Setting language to : en');
                dispatch(setLanguage('en'));
            }, show: () => language !== "en",
        }
    ] as INavbarMenuItemConfig[];

    useEffect(() => {
        // Initial greeting message for curious people
        console.log(
            `%cWelcome to Augmented Energy, powered by Noos Energy!
  🌱 %cReady to work on the energy transition?
    mailto://career@noos.energy
  ⚡️ Got comments or want to contribute?
    https://github.com/noosenergy/`,
            'color: #FAC505; font-weight: bold',
            'color: #C7C7C7; font-style: italic'
        );
    }, [])

    return (
        <ThemeProvider theme={theme}>
            <div className="App">
                <ContactUsForm
                    typeformId="hjGpAwjG"
                    showForm={showContactUsForm}
                    setShowContactUsForm={(show: boolean) => dispatch(setShowContactUsForm(show))}
                />
                <Grid container direction="column" spacing={2}>
                    <Navbar menuItemsConfig={navbarMenuItemsConfig} />
                    <Grid item style={{ paddingTop: theme.spacing(1) }}>
                        <Routes>
                            <Route path={routes.about} element={<AboutPage />} />
                            <Route path={routes.home} element={<HomePage />} />
                        </Routes>
                    </Grid>
                    <Grid
                        item
                        style={{
                            backgroundColor: theme.palette.secondary.main,
                            height: "auto",
                        }}
                    >
                        <Footer overrideCguTitle={t('footer.cgu.title')} overrideCguText={t('footer.cgu.subtitle')} />
                    </Grid>
                </Grid>
            </div>
        </ThemeProvider>
    );
}

export default App;
